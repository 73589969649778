<template>
    <div class="session-wrapper">
        <div class="session-right text-xs-center" style="margin-left: unset !important;">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="text-center">
                        <img
                            :src="appLogo"
                            class="img-responsive mb-3"
                            width="150"
                            height="150"
                        />
                    </div>
                    <v-alert
                            color="warning"
                            dense
                            dismissible
                            outlined
                            text
                            v-if="sessionExpired"
                    >{{ $t('message.sessionExpired') }}
                    </v-alert>
                    <v-alert
                            color="error"
                            dense
                            outlined
                            text
                            v-if="loginError"
                    >{{ $t(errorMessage) }}
                    </v-alert>
                    <h2 class="mb-3">{{$t('message.loginToAppic')}}</h2>
                    <div id="recaptcha-container"></div>
                    <v-form v-model="valid" class="mb-4" ref="loginForm">
                        <div class="font-sm grey--text my-0">{{ $t('message.emailId') }}</div>
                        <v-text-field
                                :rules="[...validationRules.required, ...validationRules.email]"
                                class="mt-0 pt-0"
                                required
                                v-model="email"
                        />
                        <div class="font-sm grey--text my-0">{{ $t('message.password') }}</div>
                        <v-text-field
                                :rules="[...validationRules.required]"
                                autocomplete="new-password"
                                class="mt-0 pt-0"
                                name="new-password"
                                id="new-password"
                                required
                                type="password"
                                v-model="password"
                        />
                        <v-checkbox
                                color="primary"
                                label="Remember me"
                                v-model="checkbox"
                                v-if="false"
                        />
                        <router-link class="mb-1" to="/session/forgot-password" v-if="false">{{$t('message.forgotPassword')}}?</router-link>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    block
                                    color="primary"
                                    id="login-button"
                                    large
                                    style="min-width: 100% !important;"
                                    @click="submit"
                                >
                                    <span v-if="!loggingIn">{{ $t('message.loginNow') }}</span>
                                    <span v-else>{{ $t('message.loggingIn') }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="text-center">
                        © {{ new Date().getFullYear().toString() }} APP Timber. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase/app";
    import {mapGetters} from "vuex";
    import AppConfig from "Constants/AppConfig";
    import AuthService from "../../../auth/AuthService";
    import {log} from "Helpers/helpers";
    // import { auth } from '@/firebase/Modular';
    // import {RecaptchaVerifier} from "firebase/auth";

    // const auth = new AuthService();
    // const {login, logout, authenticated, authNotifier} = auth;

    export default {
        data() {
            return {
                checkbox: false,
                valid: false,
                email: "",
                // emailRules: [
                //     v => !!v || "E-mail is required",
                //     v =>
                //         /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                //         "E-mail must be valid"
                // ],
                errorMessage: null,
                loginError: false,
                loggingIn: false,
                password: "",
                // passwordRules: [
                //     v => !!v || "Password is required",
                //     v => /^(?!.*<script\b)[\s\S]*$/.test(v) || "Password is invalid2",
                //     v => /^(?=.[A-Za-z])(?=.\d)(?!.*123)(?!.*abc)(?!.*password)(?!.test).$/.test(v) || "Password is invalid3",
                // ],
                appLogo: AppConfig.appSessionLogo,
                brand: AppConfig.brand
            };
        },
        computed:{
            ...mapGetters([
                'validationRules'
            ]),
            // concurrentLoginDetected(){
            //     const concurrentLogin = localStorage.getItem('concurrentLogin')
            //     if(concurrentLogin == 'true'){
            //         return true
            //     } else {
            //         return false
            //     }
            // },
            sessionExpired(){
                const expired = localStorage.getItem('sessionExpired')
                if(expired == 'true'){
                    return true
                } else {
                    return false
                }
            }
        },
        methods: {
            submit() {
                if(this.$refs.loginForm.validate()) {
                    // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {size: 'invisible'}, auth);
                    this.loggingIn = true
                    const user = {
                        email: this.email,
                        password: this.password
                    };
                    this.$store.dispatch("signInUserInFirebase", {user})
                        .then()
                        .catch((error) => {
                            if (error.error == true) {
                                this.errorMessage = 'message.invalidCredentials'
                                this.loginError = true
                            }
                            this.loggingIn = false
                        })
                        .finally(() => {
                            //this.loggingIn = false
                        })
                }
            },
        },
        created() {},
        mounted() {},
        beforeDestroy() {
            this.loggingIn = false
        }
    };
</script>
